<template>
  <div class="">


    <div class="row" v-for="(node, index) in data">

      <img class="imagegallery" @mouseover="hover = index" @mouseleave="hover = 999" v-on:mousemove="onMouseMove" :src="node.large" :alt="alt[index]">

      <p v-if="hover === index" :style="{ left: posx + 'px', top: posy + 'px' } " class="absolute"> {{description[index]}} </p>

    </div>

  </div>

</template>

<script>
export default {
  name: 'Gallery',
  props: {

  },
  data() {
    return {
      data: [],
      description: [],
      bilder: [],
      alt: [],
      actualDate: "",
      actualYear: "",
      posx:"",
      posy:"",
      hover: 999,
    }
  },
  methods: {

    fetchContent(){

      fetch('https://undicht.be/api/v3/images.json')  .then((result) => {
        return result.json();
      }).then((data) => {

        var i;
        for (i in data) {
          this.data.push(data[i].acf.bild.sizes);
          this.description.push(data[i].acf.unterschrift);
          this.bilder.push(data[i].acf.bild);
          this.alt.push(data[i].acf.alt);
        }

        // console.log(this.description[1]);

      });

    },

    onMouseMove(e) {
      // console.log('moved');

      this.posx = event.pageX + 20;
      this.posy = event.pageY;
      // console.log(this.posx + "and" + this.posy);

      console.log(this.hover);

    },

  },
  mounted: function(){
    this.fetchContent();
  },

  computed: {

  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">



* {
  box-sizing: border-box;
}

.absolute {
  position: absolute;
  background-color: white;
  // opacity: 0.5;

}

.row {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
  padding: 0 4px;
}

.imagegallery {
  margin-top: 8px;
  vertical-align: middle;
  width: 100% !important;
  height: 100% !important;
  filter: grayscale(100%);
}

img:hover {

  filter: grayscale(0%);

}

</style>
