<template>
  <div class="container">

    <table>

      <tr>
        <!-- <th>Datum</th>
        <th>Zeit</th>
        <th>Location</th> -->
        <!-- <th></th> -->
      </tr>

      <!-- <tr v-if="node.date >= actualDate" v-for="node in data"> -->
      <tr v-if="node.date >= actualDate" v-for="node in sortedData">
        <td v-if="node.date - actualDate == 0"> Heute, {{ convert(node.date) }}  </td>
        <td v-else-if="node.date - actualDate == 1"> Morgen, {{ convert(node.date) }} </td>
        <td v-else v-html="convert(node.date)"> </td>

        <td v-if="node.time == '12:34' "> <em>tba </em></td>
        <td v-else> {{ node.time }} Uhr </td>

        <td v-if="node.targetLocation !== '' " class="url" @click="target(node.targetLocation)"> {{ node.location }} </td>
        <td v-else> {{ node.location }} </td>

        <td v-if="node.facebook !== '' "> <a :href="node.facebook" target="_blank"> <i  class="fab fa-facebook-square"></i> </a> </td>
        <td v-else> </td>
      </tr>

    </table>

  </div>
</template>

<script>
export default {
  name: 'Tabelle',
  props: {

  },
  data() {
    return {
      data: [
        {

        }
      ],
      actualDate: "",
      actualYear: "",
    }
  },
  methods: {

    fetchContent(){

      fetch('https://undicht.be/api/gig.json')  .then((result) => {
        return result.json();
      }).then((data) => {

        var i;
        for (i in data) {
          this.data.push(data[i].acf);

        }

      });

    },

    target(target){
      window.open(target,'_blank');
    },

    getdate(){
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0');
      var yyyy = today.getFullYear();

      // console.log(today = dd + '. ' + monthNames[mm] + ' ' + yyyy);

      this.actualYear = yyyy;
      this.actualDate = yyyy + mm + dd
      // console.log(this.actualDate);
    },

    compare(a, b) {
      if (a.date < b.date) {
        return -1;
      }
      if (a.date > b.date) {
        return 1;
      }
      // a muss gleich b sein
      return 0;
    },

    convert(old){

      // console.log("nodedate:" + old);

      var monthNames = ["Januar", "Februar", "März", "April", "Mai", "Juni",
      "Juli", "August", "September", "Oktober", "November", "Dezember"];

      // console.log(old)

      var yyyy = old.slice(0, 4)
      // console.log("Actual:" + yyyy);
      // console.log("This:" + this.actualYear)

      var mm = old.slice(4, 6)
      var mm = parseInt(mm)

      var dd = old.slice(6, 8)

      if (yyyy == this.actualYear){
        return (dd + ". " + monthNames[mm-1]);
      } else {
        return (dd + ". " + monthNames[mm-1] + " " + yyyy);
      }
    }

  },
  mounted: function(){
    this.fetchContent();
    this.getdate();
  },

  computed: {
    sortedData() {
      // console.log(this.data.slice().sort(this.compare));
      return this.data.slice().sort(this.compare);
    },
  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

table {

  font-family: 'PT Sans', sans-serif;
  color: lighten( black, 20% );
  font-size: 1.5rem !important;
  border-collapse: collapse;
  width: 60%;
  min-width: 400px;
  margin: 0 auto;

  table-layout: auto;
}

.url {
  color: darken( pink, 50% );
}

.url:hover{
  text-decoration: underline;
  text-decoration-style: wavy;
  -webkit-text-decoration-style: wavy;
  -moz-text-decoration-style: wavy;

  cursor: pointer;

}

td, th {
  text-align: left;
  padding: 8px;
  // border: 2px solid black;
}

tr:hover {background-color: white;}


// tr:nth-child(even) {
//   background-color: #dddddd;
// }

img {
  cursor: pointer;
  width: 30px;
}

.eiern{
  display: inline;
  margin-left: -3px;
}

@media only screen and (max-width: 550px) {

  table {

    font-family: 'PT Sans', sans-serif;
    // font-size: 3vw;
    font-size: 4vw !important;
    color: lighten( black, 20% );
    border-collapse: collapse;
    width: 90%;
    min-width: 0px;
    margin: 0 auto;

    table-layout: auto;
  }

  img {
    cursor: pointer;
    width: 6vw;
  }

  tr:hover {background-color: none;}


}


</style>
