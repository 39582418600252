<template>
  <div class="">

    <div v-for="index in data" class="full-accordion">

      <p @click="openaccordeon(index)" class="accordion url" v-bind:class="{ active: accordeon.includes(index) }" > {{index.frage}} </p>

      <div v-if="accordeon.includes(index)" class="panel">
        <p v-html="index.antwort"> {{}} </p>
      </div>

    </div>

  </div>

</template>

<script>
export default {
  name: 'Accordeon',
  props: {

  },
  data() {
    return {
      data: [],
      accordeon: [],
      data: [],
    }
  },
  methods: {

    fetchContent(){

      fetch('https://undicht.be/api/vgf.json')  .then((result) => {
        return result.json();
      }).then((data) => {

        var i;
        for (i in data) {
          this.data.push(data[i].acf);
        }

      });

    },

    openaccordeon(index){

      if (this.accordeon.includes(index)){
        var arrayposition = this.accordeon.indexOf(index);
        // console.log(arrayposition);
        if (arrayposition > -1) {
          this.accordeon.splice(arrayposition,1)
        }

      } else {
        this.accordeon.push(index);
        // console.log(this.accordeon);
      }
    },

  },
  mounted: function(){
    this.fetchContent();
  },

  computed: {

  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

span{
  font-size: 2vw;
}

.full-accordion{
  margin:20px;
}

.accordion {
  background-color: pink;
  color: #444;
  cursor: pointer;
  padding: 15px;
  width: 100%;
  border: 2px solid black;
  text-align: left;
  outline: none;
  transition: 0.4s;
}

.active, .accordion:hover {
  background-color: white;
}

.panel {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0 18px;
}

.url {
  color: lighten( black, 20% );
}

@media only screen and (max-width: 550px) {

  .accordion {
    background-color: pink;
    color: #444;
    cursor: pointer;
    padding: 8px;
    width: 95%;
    border: 2px solid black;
    text-align: left;
    outline: none;
    transition: 0.4s;
  }

  .full-accordion{
    margin:5px;
  }

}

</style>
