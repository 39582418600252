<template>
  <div class="gigs">

    <NavTill> </NavTill>

    <div class="box-text">

      <h2>Musik</h2>

      <!-- <div class="iframe-container"> -->
      <iframe class="bandcamp" style="border: 0;" src="https://bandcamp.com/EmbeddedPlayer/album=3640953662/size=large/bgcol=ffffff/linkcol=f171a2/minimal=true/transparent=true/" seamless><a href="http://undicht.bandcamp.com/album/ch-imer-nackt-sy">chöimer nackt sy? by uñdicht</a></iframe>
      <!-- </div> -->

      <p> Wenn Peter Reber mal mit Nena geschlafen hätte, wäre Uñdicht ihre Tochter. Auch be&shyschrieben als «Faber aber Faber ist nicht dabei und alle sehen scheisse aus» machen die fünf Freunde Wil&shyhelm (g), Traugott (hb), Muammar (g), Finn-Liam (b) und Donatello (dr) soliden Mundart&shyrock mit gelegen&shytlich bluesig-funkigen Be&shyschlägen und einer satten Portion häpp&shychläpp zusammen&shygeschusterten Texten. Ein Hauch Techno und eine Prise Volks&shymusik dazu, et voilà: Eine Band, die Musik-Platt&shyitüden wie «un&shyverwechsel&shybaren Stil» oder «einzig&shyartige  Stimmung» tatsächlich mal bringt. Und wenn Du nicht weisst, ob du kommen sollst: Frag dini Mueter. </p>


      <!-- <p> Einiges am Pressetext mag gelogen sein, aber der Teil über die Musik stimmt garantiert. Uñdicht! sind Bastian, Lorenz, Max, Sebastian und Til. Die schlimmste Frage, die man uns stellen kann, ist, welchen Musikstil wir denn so pflegen - das ist schlicht nicht zu beantworten. Wir bedienen uns an Rock'n'roll, Funk und Blues gleichermassen, es steckt aber genausoviel Züri West, Stoptheshoppers und Chlöisu Friedli drin. Techno haben wir auch mal gehört und für einigermassen gut befunden. Und das Hackbrett würgt noch so was vom Volksmusikstadl rein, aber wirklich nur ein Häuchlein. Die Texte sind bis auf wenige Ausnahmen auf Berndeutsch.</p> -->

      <!-- <i v-if="this.$store.state.playing == false" class="fa fa-play-circle playbutton" @click="playbutton()"></i>
      <i v-else class="fa fa-pause-circle playbutton" @click="playbutton()"></i> -->

      <!-- <iframe class="youtube"
      src="https://www.youtube.com/embed/tgbNymZ7vqY">
    </iframe> -->

    <MusicBar> </MusicBar>

    <Videos> </Videos>

  </div>

  <!-- <input type="text" name="" :value="currentPlayTime"> -->
  <!-- <audio controls>
  <source src="../assets/kater.mp3" type="audio/mpeg">
  Your browser does not support the audio tag.
</audio> -->

</div>

</div>
</template>

<script>
import { mapGetters } from 'vuex';

// @ is an alias to /src
import NavTill from '@/components/NavTill.vue'
import MusicBar from '@/components/MusicBar.vue'
import Videos from '@/components/Videos.vue'

export default {
  name: 'Musik',
  components: {
    NavTill,
    MusicBar,
    Videos
  },
  data() {
    return {
      playing: false,
    }
  },
  methods: {
    playbutton(){
      this.playing = !this.playing;
      this.$store.commit('playpause');
    },
    prepare(){
      this.$store.commit('assignsong');
    }
  },
  mounted: function(){
    this.prepare();
  },

  computed: {

    ...mapGetters(['currentPlayTime'])

  },

}
</script>

<style scoped lang="scss">

.playbutton {
  color: darken( pink, 50% ) !important;
}

.iframe-container {
  overflow: hidden;
  // Calculated from the aspect ration of the content (in case of 16:9 it is 9/16= 0.5625)
  padding-top: 56.25%;
  position: relative;
  margin-bottom: 3rem;
}

.iframe-container iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.bandcamp{
  align: left !important;
  display: block;
  width: 350px;
  height: 350px;
}

@media only screen and (max-width: 550px) {

  .bandcamp{
    width: 200px;
    height: 200px;
  }

}

</style>
