<template>
  <div class="container">

    <div class="container" >
      <a target="_blank" href="https://open.spotify.com/artist/2EoHSjMnlHvEcoi4HnpW8O?si=gcKMyxh7TleLDRMy7Xf2Sg"> <i class="fab fa-spotify"></i> </a>
      <a target="_blank" href="https://music.apple.com/ch/album/schnous%C3%A9e-single/1477508181?l=en"> <i class="fab fa-itunes-note"></i> </a>
      <a target="_blank" href="https://undicht.bandcamp.com/releases"> <i class="fab fa-bandcamp"></i> </a>
      <a target="_blank" href="https://soundcloud.com/user-648665728"> <i class="fab fa-soundcloud"></i> </a>
      <a target="_blank" href="https://www.youtube.com/channel/UCoRFAp3w-1UdBv9Su0jLBeg"> <i class="fab fa-youtube"></i> </a>

    </div>

  </div>
</template>

<script>
export default {
  name: 'MusicBar',
  props: {

  },
  data() {
    return {
      actualDate: "",

    }
  },
  methods: {

  },

  mounted: function(){

  },

  computed: {

  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.container {
  text-align: left;
}

img {
  display: inline-block;
  text-align: left;
  margin-right: 20px;
}

i {
  font-size: 5vw;
  margin-right: 50px;
  margin-bottom: 50px;
  color: darken(pink, 50);
}

@media only screen and (max-width: 700px) {

  i {
    font-size: 7vw;
    margin-right: 30px;
    margin-bottom: 50px;
    color: darken(pink, 50) !important;
  }



}


</style>
