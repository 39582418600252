<template>
  <div class="container">

    <h2>Videos</h2>

    <div class="" v-for="video in sortedData">

      <div class="iframe-container">

        <iframe :src="video.youtube" allowfullscreen></iframe>

      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'Videos',
  props: {

  },
  data() {
    return {
      actualDate: "",
      data: [],

    }
  },
  methods: {

    fetchContent(){

      fetch('https://undicht.be/api/videos.json')  .then((result) => {
        return result.json();
      }).then((data) => {

        var i;
        for (i in data) {
          this.data.push(data[i].acf);
        }

      });

    },

    compare(a, b) {
      if (a.sortierung < b.sortierung) {
        return -1;
      }
      if (a.sortierung > b.sortierung) {
        return 1;
      }
      // a muss gleich b sein
      return 0;
    },

  },

  mounted: function(){

    this.fetchContent();

  },

  computed: {

    sortedData() {
      return this.data.slice().sort(this.compare);
    },

  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.iframe-container {
  overflow: hidden;
  // Calculated from the aspect ration of the content (in case of 16:9 it is 9/16= 0.5625)
  padding-top: 56.25%;
  position: relative;
  margin-bottom: 3rem;
}

.iframe-container iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.container {
  text-align: left;
}

img {
  display: inline-block;
  text-align: left;
  margin-right: 20px;
}

i {
  font-size: 5vw;
  margin-right: 50px;
  margin-bottom: 50px;
  color: darken(pink, 50);
}

@media only screen and (max-width: 700px) {

  i {
    font-size: 10vw;
    margin-right: 30px;
    margin-bottom: 50px;
    color: darken(pink, 50) !important;
  }



}


</style>
