<template>
  <div class="gigs">

    <NavBasti class="nav"> </NavBasti>

    <div class="box-text">
      <h2>Nächste Gigs</h2>
    </div>

    <Tabelle class="tabelle"> </Tabelle>

  </div>
</template>

<script>
// @ is an alias to /src
import NavBasti from '@/components/NavBasti.vue'
import Tabelle from '@/components/Tabelle.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'home',
  components: {
    NavBasti,
    Tabelle,
    Footer
  },
  data() {
    return {
      gigs: [],
    }
  },
  methods: {

  },
  mounted: function(){

  },
}
</script>

<style scoped lang="scss">

.home{

  background-color: black;
}

.tabelle{
  margin: auto;
  margin-bottom: 100px;
}


</style>
