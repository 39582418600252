<template>
  <div class="impressum">

    <Navigation class=""> </Navigation>

    <div class="box-text">

      <h2>Impressum</h2>

      <p>
        Uñdicht <br>
        Sebastian Schafer <br>
        Ostring 19 <br>
        3006 Bern</p>

        <p> <a class="url" href="mailto:apropos@undicht.be"> apropos@undicht.be </a> </p>

        <p>  Das Urheber&shyrecht sowie alle anderen Rechte an Texten, Bildern, Fotos oder Dateien auf dieser Web&shyseite gehören Uñdicht.
          Ins&shybesondere unsere Fotos dürfen nicht ohne Ge&shynehmigung weiter&shyverbreitet werden. Frag uns doch kurz!</p>

          <p> Diese Webseite wurde entworfen und erstellt von Nick Schneeberger.</p>

          <p>  <a class="url" target="_blank" href="http://www.nickschnee.ch"> nickschnee.ch </a> </p>


          <div class="full-accordion">

            <p @click="openaccordeon(1)" class="accordion" v-bind:class="{ active: accordeon.includes(1) }" > Copyright </p>

            <div v-if="accordeon.includes(1)" class="panel">
              <p> Das Copyright für sämtliche Inhalte dieser Website liegt bei Uñdicht. </p>
            </div>

          </div>

          <div class="full-accordion">

            <p @click="openaccordeon(4)" class="accordion" v-bind:class="{ active: accordeon.includes(4) }" > Matomo Webanalyse</p>

            <div v-if="accordeon.includes(4)" class="panel">
              <p>Wir machen eine Web&shyanalyse um zu erfahren, wie viele neue Fans sich täglich auf unserer Web&shyseite tummeln, ob wirklich jemand Baschis Gesicht anklickt und ob wir den Sprung nach Amerika schon ge&shyschafft haben. Keine Angst! Wir erfahren nicht, welche schmutzigen Film&shychen du dir gestern rein&shygezogen hast oder dass dein Handy geklaut ist.</p>

              <p>Für die Web&shyanalyse verwenden wir den Open-Source-Dienst <a target="_blank" href="http://www.matomo.org">Matomo</a>. Matomo läuft auf unserem <strong> Server in der Schweiz </strong> und nur die Band und der Web&shyadmin&shyistrator haben Zugriff auf die anonym&shysiert ge&shysammelten Daten. </p>

              <p>Matomo verwendet so&shygenannte Cookies. Das sind Text&shydateien, die auf deinem Computer ge&shyspeichert werden und die eine Analyse der Be&shynutzung der Website durch dich ermöglichen. Dazu werden die durch den Cookie erzeugten In&shyformationen über die Be&shynutzung dieser Web&shyseite auf unserem Server in der Schweiz ge&shyspeichert. Die IP-Adresse wird vor der Speicherung anonym&shyisiert. Die durch den Cookie erzeugten Informationen über die Benutzung dieser Website werden nicht an Dritte weiter&shygegeben.</p>

              <p>Wenn du mit der Speicherung und Aus&shywertung deiner Daten nicht ein&shyverstanden bist, kannst du diese gleich hier de&shyaktivieren.</p>

              <iframe
              style="border: 0; height: 200px; width: 100%;"
              src="http://analytics.nickschnee.ch/index.php?module=CoreAdminHome&action=optOut&language=en&backgroundColor=&fontColor=333333&fontSize=16px&fontFamily=Avenir"
              ></iframe>


            </div>

          </div>

          <div class="full-accordion">

            <p @click="openaccordeon(2)" class="accordion" v-bind:class="{ active: accordeon.includes(2) }" > Haftungsausschluss </p>

            <div v-if="accordeon.includes(2)" class="panel">
              <p> Alle Texte und Links wurden sorgfältig geprüft und werden laufend aktualisiert.</p>

              <p>Wir sind bemüht, richtige und vollständige Informationen auf dieser Website bereitzustellen, übernehmen aber keinerlei Verantwortung, Garantien oder Haftung dafür, dass die durch diese Website bereitgestellten Informationen, richtig, vollständig oder aktuell sind. Wir behalten uns das Recht vor, jederzeit und ohne Vorankündigung die Informationen auf dieser Website zu ändern und verpflichten uns auch nicht, die enthaltenen Informationen zu aktualisieren.</p>

              <p>Alle Links zu externen Anbietern wurden zum Zeitpunkt ihrer Aufnahme auf ihre Richtigkeit überprüft, dennoch haften wir nicht für Inhalte und Verfügbarkeit von Websites, die mittels Hyperlinks zu erreichen sind. Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die durch Inhalte verknüpfter Seiten entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen wurde. Dabei ist es gleichgültig, ob der Schaden direkter, indirekter oder finanzieller Natur ist oder ein sonstiger Schaden vorliegt, der sich aus Datenverlust, Nutzungsausfall oder anderen Gründen aller Art ergeben könnte.</p>

              <p> Für die Sicherheit der Datenübertragung im Internet können wir keine Gewähr übernehmen, insbesondere besteht bei der Übertragung von Daten per E-Mail die Gefahr des Zugriffs durch Dritte. </p>

              <p>Einer Nutzung der im Impressum veröffentlichten Kontaktdaten durch Dritte zu Werbezwecken wird hiermit ausdrücklich widersprochen. Der Betreiber behält sich für den Fall unverlangt zugesandter Werbe- oder Informationsmaterialien ausdrücklich rechtliche Schritte vor.</p>

              <p>Sollten einzelne Regelungen oder Formulierungen dieses Haftungsausschlusses unwirksam sein oder werden, bleiben die übrigen Regelungen in ihrem Inhalt und ihrer Gültigkeit hiervon unberührt.</p>

            </div>

          </div>

          <Footer id="footer"> </Footer>

        </div>


      </div>
    </template>

    <script>
    // @ is an alias to /src
    import Navigation from '@/components/Navigation.vue'
    import Footer from '@/components/Footer.vue'


    export default {
      name: 'impressum',
      components: {
        Navigation,
        Footer
      },data() {
        return {
          data: [],
          accordeon: [],
        }
      },
      methods: {

        openaccordeon(index){

          if (this.accordeon.includes(index)){
            var arrayposition = this.accordeon.indexOf(index);
            console.log(arrayposition);
            if (arrayposition > -1) {
              this.accordeon.splice(arrayposition,1)
            }

          } else {
            this.accordeon.push(index);
            console.log(this.accordeon);
          }
        },

      },
      mounted: function(){

      },
    }


    </script>

    <style scoped lang="scss">

    .home{

    }

    .navigation {
      margin-bottom: 150px;
    }

    .url {
      color: darken( pink, 50% );
      text-decoration: none;
    }

    .url:hover{
      text-decoration: underline;
      text-decoration-style: wavy;
      -webkit-text-decoration-style: wavy;
      -moz-text-decoration-style: wavy;

      cursor: pointer;

    }

    span{
      font-size: 2vw;
    }

    .full-accordion{
      margin:20px;
    }

    .accordion {
      background-color: pink;
      color: #444;
      cursor: pointer;
      padding: 15px;
      width: 100%;
      border: 2px solid black;
      text-align: left;
      outline: none;
      transition: 0.4s;
    }

    .active, .accordion:hover {
      background-color: white;
    }

    .panel {
      margin-top: 5px;
      margin-bottom: 5px;
      padding: 0 18px;
    }

    @media only screen and (max-width: 550px) {

      .accordion {
        background-color: pink;
        color: #444;
        cursor: pointer;
        padding: 8px;
        width: 95%;
        border: 2px solid black;
        text-align: left;
        outline: none;
        transition: 0.4s;
      }

      .full-accordion{
        margin: 5px;
      }

    }



    </style>
