<template>
  <div class="">

    <img class="gratis" src="../assets/gratis.jpg" alt="">

    <router-link class="router" tag="div" to="/museum"> ZUM MUSEUM </router-link>

    <Footer> </Footer>


  </div>
</template>

<script>
// @ is an alias to /src
import Navigation from '@/components/Navigation.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'gratis',
  components: {
    Navigation,
    Footer,

  },
  methods: {

    fetchContent(){

      fetch('https://undicht.be/api/gig.json')  .then((result) => {
        return result.json();
      }).then((data) => {

        var data = data;
        // console.log(data[0].acf.date);
        // console.log(data[1].acf.date);
        //
        // console.log(data[0].acf.name);
        // console.log(data[1].acf.name);

      });

    },

  },
  mounted: function(){
    this.fetchContent();
  },
}


</script>

<style scoped lang="scss">

.home{

}

.navigation {
  margin-bottom: 150px;
}

.gratis{

  width: 80%;
}

.router{
  margin-top: 10px;
  font-size: 1.4rem;
  cursor: pointer;
  color: darken( pink, 50% );
  text-decoration: none;
}

.router:hover{
  text-decoration: underline;
  text-decoration-style: wavy;
  -webkit-text-decoration-style: wavy;
  -moz-text-decoration-style: wavy;

  cursor: pointer;

}

</style>
