<template>
  <div class="gigs">

    <NavSeber> </NavSeber>

    <div class="box-text">

      <h2>Kontakt</h2>

      <p>Bitte nur Fanpost und Booking-Anfragen an: <a class="url" href="mailto:apropos@undicht.be"> apropos@undicht.be </a> </p>

      <!-- <MusicBar> </MusicBar> -->

    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import NavSeber from '@/components/NavSeber.vue'
import MusicBar from '@/components/MusicBar.vue'

export default {
  name: 'Kontakt',
  components: {
    NavSeber,
    MusicBar
  }
}
</script>

<style scoped lang="scss">

.home{

  background-color: black;
}

.url {
  color: darken( pink, 50% );
  text-decoration: none;
}

.url:hover{
  text-decoration: underline;
  text-decoration-style: wavy;
  -webkit-text-decoration-style: wavy;
  -moz-text-decoration-style: wavy;

  cursor: pointer;

}

</style>
