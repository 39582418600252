<template>
  <div class="">

    <div class="full-accordion">

      <form id="formquestion" action="" method="post" enctype="multipart/form-data" name="form">

        <div @click="openaccordeon(index)" class="accordion url" v-bind:class="{ active: accordeon.includes(index) }" >


          <!-- Frage -->
          <input v-model="question" v-on:keyup="validateQuestion" type="text" name="question" value="" placeholder="Eine Frage stellen..." onfocus="this.placeholder=''" onblur="this.placeholder='Eine Frage stellen...'"> </input>

        </div>

        <div v-if="question != '' && sent == false" class="accordion url" >

          <!-- Name -->
          <input :class="{alert: showalert && name == '' } " @input="removealert" v-model="name" type="text" name="name" value="" placeholder="Gib hier deinen Namen ein." onfocus="this.placeholder=''" onblur="this.placeholder='Gib hier deinen Namen ein.'"> </input>

        </div>

        <!-- <div v-if="name != ''" class="accordion url" > -->

        <!-- E-Mail -->
        <!-- <input type="text" name="email" value="" placeholder="optionale E-Mail für Rückfragen" onfocus="this.placeholder=''" onblur="this.placeholder='E-Mail für Rückfragen'"> </input>

      </div> -->

      <!-- <div v-if="this.showrobot == true " class="accordion url white" > -->

      <!-- Stichprobe! -->
      <!-- <input class="desktophidden" type="text" v-model="robot" name="robot" value="" placeholder="verdachtsunabhängige Personenkontrolle: Bist du ein Roboter?" onfocus="this.placeholder=''" onblur="this.placeholder='verdachtsunabhängige Personenkontrolle: Bist du ein Roboter?'"> </input>

      <input class="mobilehidden" type="text" v-model="robot" name="robot" value="" placeholder="Bist du ein Roboter?" onfocus="this.placeholder=''" onblur="this.placeholder='verdachtsunabhängige Personenkontrolle: Bist du ein Roboter?'"> </input>

    </div> -->

    <!-- <div class="accordion accordion-submit url" >

      <p>Frage stellen ...</p>

    </div> -->

    <button @click="askQuestion()" v-if="name != '' && this.showrobot == false" class="button-send" type="button" name="button"> <i class="fas fa-chevron-right"></i> Abschicken</button>

    <!-- <div @click="askQuestion()" v-if="robot.toLowerCase() == 'nein' || robot.toLowerCase() == 'nö' || robot.toLowerCase() == 'nei' " class="accordion accordion-submit url" >

    <p>Gut, dann drücke hier, um deine Frage zum schicken.</p>

  </div> -->

</form>


<!-- Für PHP -> $firstname = $_POST['firstname']; -->


<!-- <div  v-if="accordeon.includes(index)" class="accordion url" v-bind:class="{ activeless: accordeon.includes(index) }" >

<input type="text" name="" value="" placeholder="Erzähl uns, wer du bist..."> </input>

</div> -->

</div>

</div>

</div>

</template>

<script>
export default {
  name: 'AskQuestion',
  props: {

  },
  data() {
    return {
      data: [],
      accordeon: [],
      data: [],
      question:'',
      name: '',
      index: false,
      robot: 'nein',
      showrobot: false,
      sent: false,
      showalert: false,
    }
  },
  methods: {

    removealert(){
      this.showalert = false;
    },

    validateQuestion: function(e){

      if (e.keyCode === 13) {

        // this.showalert = true;

        this.showalert = !this.showalert;

      } this.log += e.key;


    },

    fetchContent(){

      fetch('https://undicht.be/api/vgf.json')  .then((result) => {
        return result.json();
      }).then((data) => {

        var i;
        for (i in data) {
          this.data.push(data[i].acf);
        }

      });

    },

    openaccordeon(index){

      if (this.accordeon.includes(index)){
        var arrayposition = this.accordeon.indexOf(index);
        // console.log(arrayposition);
        if (arrayposition > -1) {
          this.accordeon.splice(arrayposition,1)
        }

      } else {
        this.accordeon.push(index);
        // console.log(this.accordeon);
      }
    },

    roboon(){
      this.showrobot = true;
    },

    askQuestion(){
      // https://time2hack.com/2018/08/upload-files-to-php-backend-using-fetch-formdata/

      var form = new FormData(document.querySelector('#formquestion'));
      var url = 'https://undicht.be/api/question.php';
      // https://flaviocopes.com/fetch-api/#body-content

      var request = new Request(url, {
        method: 'POST',
        body: form
      })

      // if (this.robot !== ''){
      if (1){

        // Fetch: Sende das neue Objekt per AJAX an den Server
        fetch(request)
        .then(response => response.text())
        .then(data => {
          /*  Erhalte als Antwort alle Objekte
          und ersetze das aktuelle HTML mit dem neuen vom Server */
          console.log(data);

          if (data == 1){
            this.question = "Frage wurde geschickt!";
            this.name = "";
            this.email = "";
            this.robot = "";
            this.showrobot = false;

            this.sent = true;

            setTimeout(function () {

              this.question = "";
              this.sent = false;


            }.bind(this), 5000)

          } else {

            alert("Der Computer sagt nein. Bitte versuche es später nochmals.");

          }


        })
        .catch(error => {
          alert("Der Computer sagt nein. Bitte versuche es später nochmals.")

        }) // Ende fetch

      }

    }

  },
  mounted: function(){
    this.fetchContent();
  },

  computed: {

  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

span{
  font-size: 2vw;
}

.button-send{

  border: none;
  color: white;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 30px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;

  background-color: pink;
  color: black;
  // border: 2px solid darken( pink, 10% );
  border-radius: 5px;
  margin-top: 5px;
}

.button-send:hover {
  // background-color: darken( pink, 10% );
  // color: white;

  color: darken( pink, 50% );
  // text-decoration: underline;
  // text-decoration-style: wavy;
}

.full-accordion{
  margin:20px;
}

.accordion {
  background-color: pink;
  color: #444;
  padding: 15px;
  width: 100%;
  border: 2px solid black;
  text-align: left;
  outline: none;
  transition: 0.4s;
}

.active, .accordion:hover {
  background-color: white;
}

.active-less {
  background-color: white;
}

.panel {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0 18px;
}

.url {
  color: lighten( black, 20% );
}

input{
  background-color: transparent;
  border: 0px solid;
  height: 2rem;
  width: 100%;
  font-size: 1.6rem;
  color: lighten( black, 20% );
  text-align: left;
}

::-webkit-input-placeholder {
  color:lighten( black, 20%);
  opacity: 0.96;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  font-size: 1.5rem;
}

::-moz-placeholder {
  color:lighten( black, 20%);
  opacity: 0.96;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
}

::-ms-placeholder {
  color:lighten( black, 20%);
  opacity: 0.96;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
}

::placeholder {
  color:lighten( black, 20%);
  opacity: 0.96;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
}









.alert::-webkit-input-placeholder {
  color: darken(pink, 30%);
  opacity: 0.96;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
}

.alert::-moz-placeholder {
  color: darken(pink, 30%);
  opacity: 0.96;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
}

.alert::-ms-placeholder {
  color: darken(pink, 30%);
  opacity: 0.96;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
}

.alert::placeholder {
  color: darken(pink, 30%);
  opacity: 0.96;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
}










.accordion-submit:hover{
  background-color: darken( pink, 10% );
  cursor: pointer;
}

.mobilehidden{
  display: none;
}

// .alert{
//   color: darken(pink, 30%);
// }

input[type=text] {
  /* Remove First */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

*:focus {
  outline: none;
}


@media only screen and (max-width: 1250px) {

  .desktophidden{
    display: none;
  }

  .mobilehidden{
    display: block;
  }

  input[type=text] {
    /* Remove First */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

}

@media only screen and (max-width: 700px) {

  .accordion {
    background-color: pink;
    cursor: pointer;
    padding: 8px;
    width: 95%;
    border: 2px solid black;
    text-align: left;
    outline: none;
    transition: 0.4s;
  }

  .full-accordion{
    margin:5px;
  }

  input{
    background-color: transparent;
    border: 0px solid;
    height: 2rem;
    width: 95%;
    font-size: 1rem;
    color: lighten( black, 20% );
    text-align: left;
  }

  .button-send{
    font-size: 1rem;
  }

  ::-webkit-input-placeholder {
    color:lighten( black, 20%);
    font-size: 0.9rem;
  }

  ::-moz-placeholder {
    color:lighten( black, 20%);
  }

  ::-ms-placeholder {
    color:lighten( black, 20%);
  }

  ::placeholder {
    color:lighten( black, 20%);
  }





  .desktophidden{
    display: none;
  }

  .mobilehidden{
    display: block;
  }

  input[type=text] {
    /* Remove First */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

}

</style>
