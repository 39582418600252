<template>
  <div class="gigs">

    <NavMax> </NavMax>

    <div class="box-text">

      <h2>Galerie</h2>

      <Gallery class="gallery"> </Gallery>

    </div>
    
  </div>
</template>

<script>
// @ is an alias to /src
import NavMax from '@/components/NavMax.vue'
import Gallery from '@/components/Gallery.vue'

export default {
  name: 'Galerie',
  components: {
    NavMax,
    Gallery
  }
}
</script>

<style scoped lang="scss">

.home{

  background-color: black;
}

.gallery{
  width: 90%;
  margin: auto;
}

</style>
