<template>
  <div class="flex">

    <!-- <div class=""> -->

    <div class="flex-sebi-none">
      <a href="../#/kontakt"> <img class="sebi" src="../assets/nav_till_sebi.png" alt="Hör rein ins neue Album deiner Lieblingsband Uñdicht."> </a>
    </div>

      <div class="container">
        <img class="image-nav" src="../assets/nav_till_small.png" alt="Chöimer nackt sy? Fragt deine Lieblingsband im neuen Album.">

        <router-link v-on:click.native="playjingle(1)" class="router" id="route-gigs" tag="div" to="/gigs"> </router-link>
        <router-link v-on:click.native="playjingle(2)" class="router" id="route-musik" tag="div" to="/musik"> </router-link>
        <router-link v-on:click.native="playjingle(3)" class="router" id="route-galerie" tag="div" to="/galerie"> </router-link>
        <router-link v-on:click.native="playjingle(4)"class="router" id="route-vigf" tag="div" to="/vigf"> </router-link>
        <router-link v-on:click.native="playjingle(5)" class="router" id="route-kontakt" tag="div" to="/kontakt"> </router-link>

      </div>

      <router-link tag="div" to="/kontakt" class="flex-sebi" v-on:click.native="playjingle(5)">
        <!-- <router-link v-on:click.native="playjingle(5)" class="router" id="route-kontakt" tag="a" to="/kontakt">
          <img class="sebi" src="../assets/nav_till_sebi.png" alt="">
        </router-link> -->
        <a href="../kontakt"> <img class="sebi" src="../assets/nav_till_sebi.png" alt=""> </a>
      </router-link>

    <!-- </div> -->



  </div>
</template>

<script>
export default {
  name: 'NavTill',
  props: {

  },
  methods: {
    playjingle(index){
      this.$store.commit('playjingle', index);
    },

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.flex {
  display: flex;
  // background: blue;
}

.flex-sebi{
  align-self: right;

}

.flex-sebi-none{
  visibility: hidden;

}

.container {
  position: relative;
  width: 800px;
  margin: auto;
  // background-color: blue;
}

#route-gigs{
  width:20%;
  height:90%;
  // background:rgba(255,255,255,0.5);
  top:5%;
  left:7%;
}

#route-musik{
  width:17%;
  height:90%;
  // background:rgba(255,255,255,0.5);
  top:5%;
  left:27%
}

#route-galerie{
  width:15%;
  height:90%;
  // background:rgba(255,255,255,0.5);
  top:5%;
  left:44%
}

#route-vigf{
  width:27%;
  height:90%;
  // background:rgba(255,255,255,0.5);
  top:5%;
  left:59%
}

.router{
  position: absolute;
  z-index: 1;
  border: none;
  text-align: center;
  cursor: pointer;

}

.sebi{
  float: right;
  width: 13%;
  min-width: 130px;
  // background-color: black;
}

@media only screen and (max-width: 700px) {
  .sebi{

    display: none;
  }

  .container {
    position: relative;
    width: 100%;
    margin: auto;
    // background-color: blue;
  }

}


</style>
