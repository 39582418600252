<template>
  <div class="gigs">

    <NavLorenz> </NavLorenz>

    <div class="box-text">

      <h2>VigF</h2>

      <AskQuestion class="mb"> </AskQuestion>

      <Accordeon class="accordeon"> </Accordeon>

    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import NavLorenz from '@/components/NavLorenz.vue'
import Accordeon from '@/components/Accordeon.vue'
import AskQuestion from '@/components/AskQuestion.vue'

export default {
  name: 'home',
  components: {
    NavLorenz,
    Accordeon,
    AskQuestion
  }
}
</script>

<style scoped lang="scss">

.home{
  background-color: black;
}

.accordeon{
  margin-bottom: 50px;
}

.mb {
  margin-bottom: 10%;
}


</style>
